import { useEffect, useState } from 'react';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

export const ServiceWorkerNotice = () => {
  const [registration, setRegistration] = useState();
  const [open, setOpen] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        console.log('update available');
        setRegistration(registration);
        setNeedRefresh(true);
      },
      onSuccess: (registration) => {
        setRegistration(registration);
        console.log('service worker registered');
      },
    });
  }, []);

  console.log(registration);

  useEffect(() => {
    (async function () {
      if (!registration) return;

      if (!registration.waiting) return;
      if (needRefresh) setOpen(true);
    })();
  }, [needRefresh, registration]);

  // useEffect(() => {
  //   if (!('serviceWorker' in navigator)) return;
  // }, []);

  if (!open) return null;
  return (
    <div className="service-worker-notice" open={open}>
      <h3 className="fw-bold">
        Es sind neue inhalte Verfügbar!
        <br />
      </h3>
      <div className="form-group pb-x3 gray-300">
        <p>Bitte laden Sie die Seite neu.</p>
      </div>
      <div>
        <button
          className="btn-outlined"
          onClick={async () => {
            const registration = await navigator.serviceWorker.ready;
            if (!registration.waiting) return window.location.reload();
            navigator.serviceWorker.addEventListener('controllerchange', () => {
              console.log('controllerchange');
              window.location.reload();
              // if (!refreshing) {
              // refreshing = true;
              // }
            });
            registration.waiting.postMessage('SKIP_WAITING');

            // window.location.reload(true);
          }}
        >
          Neu laden
        </button>
      </div>
    </div>
  );
};
